import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import Header from "./header"


function Layout({ children }) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className="flex flex-col font-sans min-h-screen bg-gray-200 text-gray-700 relative min-h-screen">
      <main className="min-w-full bg-gray-200 pb-16">
      
        <div className="relative flex flex-col flex-1 md:justify-center max-w-4xl mx-auto py-4 px-4 w-full"></div>
      </main>
     
      <footer className="w-full bg-green-lighter absolute bottom-0 h-16">
        <nav className="flex max-w-4xl mx-auto text-sm">
          <div className="text-right w-full">
          <p className="text-white"><a href="/privacy/" className="text-white px-5">Datenschutz</a> &#183; <a  className="text-white px-5" href="/impress/">Impressum</a></p>
          </div>
        </nav>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
