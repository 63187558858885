import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Logo = ({ data }) => (
  <div className="each-slide">
    <Img fixed={data.file.childImageSharp.fixed} />
  </div>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "logo.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(height: 150, width: 300, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => <Logo data={data} {...props} />}
  />
)
